@import "mixins";
@import "custom";
@import "variables";
@import "~bootstrap/scss/bootstrap";

p {
	margin-bottom: 0;
}

h5 {
	font-size: inherit;
	margin-bottom: inherit;
	font-weight: inherit;
	line-height: inherit;
}

a > img {
	//border-bottom: 5px solid red;
	//border: none;
}

img {
	width: 100%;
	height: 100%;
}
//black on white
a {
	@include link-active;

	&:hover {
		@include link-hover;
	}
}

//.emerge { opacity: 0; }

.header__link {
	@include link-dark;
	margin-right: .8em;
	transition: none;
	width: fit-content;
	white-space: nowrap;
	@media (min-width: 768px) {
		margin-right: 1em;

	}
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		transition: none;
		@include light-hover;
	}
}
.ava__link {
	@include link-dark;
	&:hover {
		@include light-hover;
	}
}
.active {
	//padding: 0.15em 0.304em 0.2em;
	padding: 0.2em 0.304em 0.2em;
	border-radius: 0.35em;
	color: #fff;
	background-color: #1a1a1a;
	border-bottom: none;
	transition: none;
	width: fit-content;

	&:hover {
		border: none;
		color: #fff;
	}
}
.link-active {
	@include link-active;

	&:hover {
		@include link-hover;
	}
}
.link-deactive {
	border: none;
	&:hover {
		border: none;
		color: #1A1A1A;
		cursor: default;
	}
}

.lang-arrow {
	margin-top: -.25em;
	width: 0.4em;
	height: 0.3em;
	&-reverse {
		transform: rotate(180deg);

	}
}
.img-link {
	border-bottom: none;
	&:hover {
		border-bottom: none;
	}
}
//a {
//	@include link-active;
//
//	&:hover {
//		@include link-hover;
//	}
//}
//.active {
//	padding: 3px 6px 4px;
//	color: #fff;
//	border-radius: 7px;
//	background-color: #1A1A1A;
//	border-bottom: none;
//
//	&:hover {
//		border: none;
//		color: #fff;
//	}
//}
//.header__link {
//	@include link-dark;
//	margin-right: 1em;
//
//	&:last-child {
//		margin-right: 0;
//	}
//	&:hover {
//		@include light-hover;
//		color: #323232;
//	}
//}
//.link-active {
//	@include link-active;
//
//	&:hover {
//		@include link-hover;
//	}
//}

.link-disable {
	@include link-active;
	border-bottom: none;

	&:hover {
		@include link-active;
		color: #1A1A1A;
		cursor: default;
		border-bottom: none;
	}
}

.img-link img {
	width: 100%;
}

.page {
	 position: relative;
	 font-family: $Formular;
	 font-style: normal;
	 background-color: #fafafa;
	 overflow-x: hidden;
	font-size: calc(0.723vw + 0.0073px);
	// font-size: 14px;
	//@media (min-width: 768px) {
	//	font-size: calc(0.723vw + 0.0073px);
	//
	//}
	 @media (min-width: 1920px) {
		 font-size: 14px;
	 }
 }

.header {
	z-index: 1;
	position: relative;
	margin-top: 7.89em;
	margin-bottom: 3.5em;

	@media (min-width: 768px) {
		margin-top: 2.89em;
	}
	&__name {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;

		margin-bottom: 1.2em;

		font-size: 5.5em;
		font-weight: 700;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-align: left;
		color: #FAFAFA;

		@media (min-width: 768px) {
			justify-content: flex-start;
			width: 100%;

			margin-bottom: 0;

			font-size: 1.428em;
		}
		&-img {
			display: none;
			width: 1.75em;
			margin-right: .8em;

			@media (min-width: 768px) {
				display: block;
			}
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		&-text {
			display: block;
			//display: none;
			font-size: 15px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0.01em;
			text-align: left;

			@media (min-width: 768px) {
				display: none;
			}
		}

		&-name {
			position: relative;
			@include bold-link;
			line-height: 0.55;
			font-size: 5.55em;
			@media (min-width: 768px) {
				font-size: 4.28em;
			}

			&_img {
				//display: none;
				//@media (min-width: 768px) {
				//	display: block;
				//}
			}
		}

		&-ava {
			//display: block;
			display: none;
			@media (min-width: 768px) {
				display: block;
			}
		}
	}

	&__links {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		font-size: 5.6em;
		font-weight: 400;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-align: left;

		width: 100vw;

		padding-left: 1.2em;
		padding-right: 1em;
		//padding-bottom: .5em;

		overflow-x: auto;
		&::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
			display: none !important;
			background: transparent;
		}
		@media (min-width: 768px) {
			font-size: 1.428em;
			justify-content: flex-end;
			overflow-x: inherit;
			width: auto;
			margin: 0;
			padding: 0;
		}
	}

	&__lang {
		justify-content: center;
		@media (min-width: 768px) {
			justify-content: flex-end;
		}
	}
}

.footer {
	height: 100%;
	padding-bottom: 20em;
	overflow: hidden;
	@media (min-width: 768px) {
		padding-bottom: 0;
	}

	&__container {
		height: fit-content;
		//padding-top: 6em;
		@media (min-width: 768px) {
			height: 38em;
			//padding-top: 2em;
		}
	}

	.page-info {
		padding-top: 6em;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-top: 0.8em solid #000;
		//padding-bottom: 47em;

		&::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
			display: none !important;
			background: transparent;
		}
		@media (min-width: 768px) {
			flex-direction: row;
			padding-top: 2em;
			border-top: 0.17em solid #000;
			//padding-bottom: 23.7em;
		}
		&__persons {
			overflow-x: auto;
			overflow-y: hidden;
			display: flex;
			width: 100vw;
			@include mob-regular;
			font-weight: 400;
			letter-spacing: 0.03em;

			padding-left: 1em;
			padding-right: 1em;

			margin-right: 0;
			margin-left: -1em;
			margin-bottom: 8.5em;

			&::-webkit-scrollbar {
				width: 0 !important;
				height: 0;
				display: none !important;
				background: transparent;
			}
			@media (min-width: 768px) {
				width: 100%;
				@include light-text;
				font-weight: 400;
				line-height: 1.15;
				letter-spacing: 0.01em;
				margin-bottom: 0;
			}
		}
		&__made {
			display: flex;
			align-items: flex-start;
			width: 100%;
			font-family: $Formular;
			font-size: 6.5em;
			font-style: normal;
			line-height: 1.25;
			text-align: left;
			font-weight: 400;
			letter-spacing: 0.03em;

			@media (min-width: 768px) {
				width: fit-content;
				//min-width: 13em;
				margin-right: 4em;
				@include light-text;
				font-weight: 400;
				//line-height: 1.15;
				letter-spacing: 0.01em;
			}
			img {
				display: none;
				margin-top: 0.25em;
				margin-right: 0.8em;
				width: 0.8em;
				height: 0.8em;

				@media (min-width: 768px) {
					display: block;
				}
			}

			p {
				margin-bottom: 1em;
				@media (min-width: 768px) {
					white-space: nowrap;
				}
			}
			span {
				margin-bottom: 1em;
				@media (min-width: 768px) {
					white-space: nowrap;
				}
			}
		}
		&__person {
			display: flex;
			margin-right: 2.5em;

			img {
				width: 2.55em;
				height: 2.55em;
				margin-right: .8em;
			}
		}
		&__data{
			&-name {
				margin-bottom: 0.3em;
				text-align: left;
				font-weight: 500;
				a {
					@include link-dark;
					&:hover {
						color: #130793;
					}
				}
			}

			&-proff {
				font-weight: 400;
				text-align: left;
			}
		}
		&__feedback {
			//margin-right: 2em;
			display: none;
			@media (min-width: 768px) {
				display: block;
			}
			img {
				margin-top: -2.5%;
			}

			&-desc {
				display: flex;
				align-items: flex-start;
				width: max-content;
				@include light-text;
				p{
					margin-bottom: 0;
				}
			}
			&-link{
				margin-top: -0.32em;
				margin-left: .35em;
				@include bold-link;
				font-size: 3em;

				&:hover{
					@include link-hover;
				}
			}
		}
	}

	.mobile-link {
		z-index: 0;
		position: relative;
		display: block;
		margin-bottom: 110em;

		@media (min-width: 768px) {
			display: none;
		}
		&:hover .link-active {
			@include link-hover;
		}

		&__img {
			z-index: -1;
			position: relative;
			margin-bottom: 4em;
			//margin-left: -9%;
			//margin-right: -9%;
			margin-left: -2%;
			margin-right: -2%;

			&:before {
				content: none;
				@media (min-width: 768px) {
					content: '';
					display: block;
					height: 7%;
					right: 0;
					position: absolute;
					top: 0;
					width: 5%;
					background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
				}
			}
		}

		&__link {
			z-index: 2;
			//margin-top: -1em;
			margin-top: 0;
			margin-left: 0;

			font-size: 9.3em;
			font-style: normal;
			font-weight: 500;
			line-height: 1.3;
			letter-spacing: 0.01em;
			text-align: left;

			span {
				font-weight: 400;
			}
		}
	}

	&__blocks {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		padding-top: 1%;
		margin-bottom: 1.7%;

		@include light-text;
		font-size: 4em;
		line-height: 1;
		border-top: none;

		@media (min-width: 768px) {
			flex-direction: row;
			align-items: center;
			border-top: .12em solid #000;

			font-size: 1.42em;
			line-height: 1.3;
		}
	}

	&__year {
		@include light-text;
		margin-bottom: 0.35em;
		@media (min-width: 768px) {
			margin-bottom: 0;
			font-size: 1.02em;
		}
		&-line{
			letter-spacing: 0;
		}
		p {
			margin-bottom: 0;
		}
	}

	&__links, &__dev {
		display: flex;
		justify-content: space-between;
		width: 100%;

		@include light-text;
		letter-spacing: 0;
		@media (min-width: 768px) {
			display: block;
			width: auto;
			font-size: 1.02em;
		}
		.link-active {
			margin-right: 0.56em;
			@media (min-width: 768px) {
				margin-right: .8em;
			}
			&:last-child {
				margin-right: 0;
			}
			&__inst {
				display: none;
				@media (min-width: 768px) {
					display: inline-block;
				}
			}
		}

		.link-lang {
			margin-left: 2em;
			margin-right: 0;
		}
		.link-margin {
			margin-right: 0;
		}
	}

	&__dash {
		display: none;
		letter-spacing: -.12em;
		@media (min-width: 768px) {
			display: inline-block;
		}

	}

	&__dash-mob {
		display: inline-block;
		letter-spacing: -.145em;
		@media (min-width: 768px) {
			display: none;
		}
	}
}

.br-mob {
	display: none;
	@media (min-width: 768px) {
		display:  block;
	}
}

.br-mob-block {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
}

.br-mob-inline {
	display: none;
	@media (min-width: 768px) {
		display: inline;
	}
}

.br-desk {
	display: block;
	@media (min-width: 768px) {
		display: none;
	}
}

.br-desk-inline {
	display: inline-block;
	@media (min-width: 768px) {
		display: none;
	}
}

.order-one {
	order: -1;
	@media (min-width: 768px) {
		order: 1;
	}

}

.order-two {
	order: 1;
	@media (min-width: 768px) {
		order: -1;
	}
}

.zindex {
	z-index: 10;
}

.zindex-1 {
	z-index: -10;
}

.no-scroll::-webkit-scrollbar {
	width: 0;
}

.scroll {
	overflow-x: auto;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 0 !important;
		height: 0;
		display: none !important;
		background: transparent;
	}
}

.signature {
	.sign {
		display: flex;
		align-items: center;
		justify-content: center;

		margin-top: 0.5em;
		&-icon {
			width: 7em;
			height: 7em;
			margin-right: 3em;
		}

		&-text {
			font-size: 4.9em;
			font-style: normal;
			font-weight: 400;
			//line-height: 1.2;
			line-height: 1.5;
			letter-spacing: 0;
			text-align: center;
			p {
				margin-bottom: 0;
			}
		}
	}
}

.swiper-row__mobile {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-row {
	display: block;
	position: relative;
}

.swiper-row-2 {
	display: block;
	position: relative;
}

.swiper-row-3 {
	display: block;
	position: relative;
}

.swiper-wrapper {
	display: flex;
	width: 100%;

	@media (min-width: 768px) {
		width: 100%;
	}
}

.swiper-slide {
	transition: all .25s;
	padding: 0;
}

.swiper-slide-null + .swiper-slide-active {
	margin-left: 17em;
	&:first-child {
		margin-right: -17em;
		@media (min-width: 768px) {
			margin-right: auto;
		}
	}
	&:last-child {
		margin-left: 34em;
		@media (min-width: 768px) {
			margin-left: auto;
		}
	}
}

.swiper-slide-null {
	margin-right: -17em;
	@media (min-width: 768px) {
		margin-right: auto;
	}
}

.swiper-slide picture {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
}

.swiper-pagination {
	margin: -12em auto 1em;
	display: flex;
	justify-content: center;
	@media (min-width: 768px) {
		display: none;
	}
	.swiper-pagination-bullet {
		width: 3.8em;
		height: 3.8em;
		margin: 0 2.5em 0 1.4em;
		border-radius: 50%;
		border: 0.8em solid #1A1A1A;
		background: #fff;

	}

	.swiper-pagination-bullet-active {
		background: #1A1A1A;
	}
}
.current-year {
	display: inline;
}
