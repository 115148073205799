@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/type";

$grid-breakpoints: (
	xs: 0,
	sm: 360px,
	md: 768px,
	lg: 1260px,
	xl: 1920px
);

$container-max-widths: (
	xs: 92%,
	sm: 92%,
	md: 87%,
	//lg: 1200px,
	lg: 87%,
	xl: 1676px
);

$grid-gutter-width: 1.25em;

$h5-font-size:inherit;

$headings-margin-bottom:inherit;
$headings-font-family:inherit;
$headings-font-weight:inherit;
$headings-line-height:inherit;

$link-color: #1A1A1A;
$link-decoration: none;
$link-hover-color: #130793;
$link-hover-decoration: none;
$emphasized-link-hover-darken-percentage: 10% !default;
